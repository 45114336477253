import { request } from "../utils/axios.js";

export default {
  // 营销 ==> 客情管理
  initCustomerViewList(params = {}) {
    return request("GET", `/pendingwork/init_customer_view_list`, params);
  },

  getCustomerViewList(params = {}) {
    return request("GET", `/pendingwork/get_customer_view_list`, params);
  },

  getCustomerViewDetail(params = {}) {
    return request("GET", `/pendingwork/get_customer_view_detail`, params);
  },

  assignAccepter(params = {}) {
    return request("GET", `/pendingwork/assign_accepter`, params);
  },

  completeWork(params = {}) {
    return request("POST", `/pendingwork/complete_work`, params);
  },

  deleteWork(params = {}) {
    return request("GET", `/pendingwork/delete_work`, params);
  },

  createTempWork(params = {}) {
    return request("POST", `/pendingwork/create_temp_work`, params);
  },
};
