<template>
  <div class="follow-detail-dialog">
    <el-dialog
      title="客情信息"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="68px" :inline="false" size="mini">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="主题名称">
                {{ memberInfo.WorkTypeText }}
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="本月到店">
                {{ memberInfo.ThisMonthExpeCount }}次
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="最近到店">
                {{
                  memberInfo.LastExpeTime
                    ? util.dateFormat(
                        "YYYY-mm-dd",
                        new Date(memberInfo.LastExpeTime * 1000)
                      )
                    : ""
                }}
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="客户名称">
                {{ memberInfo.MemberName }}
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="手机号码">
                {{ memberInfo.MemberPhone }}
              </el-form-item>
            </el-col>
          </el-row>

          <div class="info-box">
            <div class="service-info">
              <span class="title">服务信息</span>
              <div
                style="white-space: pre-wrap;"
                v-html="dataInfo.WorkContent"
              ></div>
            </div>

            <div class="more-info">
              <span class="title">更多信息</span>
              <div class="more-box">
                <div class="item">
                  <span class="label">生成时间：</span>
                  <span>{{
                    dataInfo.CreateTime
                      ? util.dateFormat(
                          "YYYY-mm-dd HH:MM:SS",
                          new Date(dataInfo.CreateTime * 1000)
                        )
                      : ""
                  }}</span>
                </div>

                <div class="item">
                  <span class="label">回访时间：</span>
                  <span>{{
                    dataInfo.PendingTime
                      ? util.dateFormat(
                          "YYYY-mm-dd HH:MM:SS",
                          new Date(dataInfo.PendingTime * 1000)
                        )
                      : ""
                  }}</span>
                </div>

                <div class="item" v-if="dataInfo.Status > 0">
                  <span class="label">分配时间：</span>
                  <span>{{
                    dataInfo.AssginTime
                      ? util.dateFormat(
                          "YYYY-mm-dd HH:MM:SS",
                          new Date(dataInfo.AssginTime * 1000)
                        )
                      : ""
                  }}</span>
                </div>

                <div class="item">
                  <span class="label">回访人员：</span>
                  <el-select
                    v-if="dataInfo.Status == 0"
                    v-model="memberInfo.AccepterGuid"
                    placeholder="请选择回访人员"
                    size="mini"
                  >
                    <el-option
                      v-for="item in emplList"
                      :key="item.EmplGuid"
                      :label="item.EmplName"
                      :value="item.EmplGuid"
                    >
                    </el-option>
                  </el-select>
                  <span v-else>{{ memberInfo.AccepterName }}</span>
                </div>

                <div class="item" v-if="dataInfo.Status > 0">
                  <span class="label">回访结果：</span>
                  <div class="result-box" v-if="dataInfo.Status == 1">
                    <el-select
                      v-model="result"
                      placeholder="请选择回访结果"
                      clearable
                      size="mini"
                      @change="onChangeResult"
                    >
                      <el-option
                        v-for="item in resultList"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      v-show="showResult"
                      v-model="dataInfo.CompleteRemark"
                      placeholder="请输入回访结果"
                      type="textarea"
                      :row="6"
                    ></el-input>
                  </div>

                  <span v-else>{{ dataInfo.CompleteRemark }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </span>

      <span slot="footer">
        <el-button @click="onCloseDialog">关闭</el-button>
        <template v-if="dataInfo.Status == 0">
          <el-button type="danger" plain @click="deleteWork">删除</el-button>
          <el-button
            type="primary"
            :disabled="assignDisabled"
            @click="assignAccepter(1)"
            >分配</el-button
          >
        </template>
        <template v-else-if="dataInfo.Status == 1">
          <el-button
            type="danger"
            plain
            :disabled="assignDisabled"
            @click="assignAccepter(0)"
            >取消分配</el-button
          >
          <el-button type="primary" @click="completeWork">完成</el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pendingwork from "@/api/pendingwork.js";

export default {
  props: {
    emplList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      rowSpan: 20,
      colSpan: 7,
      result: "",
      memberInfo: {
        WorkTypeText: "",
        ThisMonthExpeCount: 0,
        LastExpeTime: "",
        MemberName: "",
        MemberPhone: "",
      },
      dataInfo: {},
      resultList: [
        "承诺到店",
        "下次回访",
        "情感联络",
        "失联",
        "拒访",
        "客户表扬",
        "客户投诉",
        "其他",
      ],
      showDialog: false,
      showResult: false,
      assignDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 获取待办事项明细
    async getCustomerViewDetail() {
      try {
        let { data } = await Pendingwork.getCustomerViewDetail({
          work_guid: this.memberInfo.WorkGuid,
        });
        this.dataInfo = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 完成待办事项
    async completeWork() {
      try {
        let { errcode } = await Pendingwork.completeWork({
          WorkGuid: this.memberInfo.WorkGuid,
          Remark: this.dataInfo.CompleteRemark,
        });
        if (errcode == 0) {
          this.$message.success("已完成");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 分配人员
    assignAccepter(assign = 0) {
      let that = this;
      let assignFuction = async () => {
        try {
          that.assignDisabled = true;
          let { errcode } = await Pendingwork.assignAccepter({
            work_guid: that.memberInfo.WorkGuid,
            empl_guid: that.memberInfo.AccepterGuid,
            assign,
          });
          if (errcode == 0) {
            that.$message.success(`${assign ? "分配" : "取消分配"}成功`);
            that.$emit("success");
            that.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        } finally {
          that.assignDisabled = false;
        }
      };

      if (!assign) {
        this.$confirm("确定取消分配吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          assignFuction();
        });
        return;
      }
      assignFuction();
    },

    // 删除待办事项
    deleteWork() {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Pendingwork.deleteWork({
            work_guid: this.memberInfo.WorkGuid,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.$emit("success");
            this.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    onChangeResult(event) {
      // console.log(event);
      this.showResult = true;
      if (event === "其他") {
        return;
      }
      this.dataInfo.CompleteRemark = event;
    },

    // 打开对话框
    onShowDialog(event = {}) {
      this.memberInfo = JSON.parse(JSON.stringify(event));
      this.getCustomerViewDetail();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-form {
    .info-box,
    .info-box > div {
      .flex-col;
      align-items: unset;
    }

    .info-box {
      > div {
        > span,
        > div {
          margin: 5px 0;
        }

        > div {
          padding: 10px;
          box-sizing: border-box;
          border-radius: 10px;
          background: #f1f1f1;
          color: #444;

          > div {
            .flex-row;
            align-items: unset;
          }
        }

        .title {
          font-size: 18px;
          color: #303133;
        }
      }

      .more-info {
        .more-box {
          padding: 0;
          > div {
            margin: 10px;

            .result-box {
              flex: 1;
              .el-textarea {
                flex: 1;
                margin: 10px 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
