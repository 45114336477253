<template>
  <div>
    <el-dialog
      title="临时客情"
      :visible.sync="showDialog"
      width="700px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="df-row">
          <div class="df-col">
            <DfMember
              v-model="work"
              ref="DFMember"
              size="mini"
              placeholder="查询一名会员进行添加"
              @change="onSelectMember"
              @success="resetPageData"
            ></DfMember>
          </div>

          <div class="df-col">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="onAddData"
              >添 加</el-button
            >
          </div>
        </div>

        <div class="df-table">
          <div class="df-table-header">
            <div class="df-tr">
              <div class="df-th name">客户</div>
              <div class="df-th phone">手机号</div>
              <div class="df-th date">回访日期</div>
              <div class="df-th accepter">回访人</div>
              <div class="df-th control"></div>
            </div>
          </div>

          <div class="df-table-body">
            <div class="df-tr" v-for="(item, index) in tableList" :key="index">
              <div class="df-td name">{{ item.MemberName }}</div>
              <div class="df-td phone">{{ item.MemberPhone }}</div>
              <div class="df-td date">
                <el-date-picker
                  v-model="item.PendingTime"
                  type="date"
                  size="mini"
                  placeholder="请选择"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
              <div class="df-td accepter">
                <el-select
                  v-model="item.AccepterGuid"
                  size="mini"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in emplList"
                    :key="item.EmplGuid"
                    :label="item.EmplName"
                    :value="item.EmplGuid"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="df-td control">
                <el-button
                  type="danger"
                  size="mini"
                  @click="onRemoveData(index)"
                  >删 除</el-button
                >
              </div>

              <div class="special">
                <span>回访内容：</span>
                <el-input
                  v-model="item.WorkContent"
                  type="textarea"
                  :rows="3"
                  resize="none"
                  placeholder="请输入回访内容"
                  size="mini"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="createDisabled"
          @click="createTempWork"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pendingwork from "@/api/pendingwork.js";

export default {
  props: {
    emplList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      work: '',
      memberInfo: {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
      },
      tableList: [],
      showDialog: false,
      createDisabled: false,
    };
  },

  mounted() {},

  methods: {
    async createTempWork() {
      for (let i = 0; i < this.tableList.length; i++) {
        console.log(this.tableList[i]);
        if (await !this.tableList[i].PendingTime) {
          return this.$message.info("请选择回访日期");
        } else if (!this.tableList[i].AccepterGuid) {
        //   return this.$message.info("请选择回访人");
        }
      }
      this.createDisabled = await true
      await this.$nextTick(async () => {
        try {
          let { errcode } = await Pendingwork.createTempWork(this.tableList);
          if (errcode == 0) {
            this.$message.success("临时客情添加成功");
            this.$emit('success');
            this.resetPageData()
            this.tableList = []
            this.onCloseDialog()
          }
        } catch (err) {
          console.log(err);
        } finally {
            this.createDisabled = false
        }
      });
    },

    onAddData() {
      if (!this.memberInfo.MemberGuid) {
        return this.$message.info("请先选择会员");
      }
      this.tableList.push({
        MemberGuid: this.memberInfo.MemberGuid,
        MemberName: this.memberInfo.MemberName,
        MemberPhone: this.memberInfo.MemberPhone,
        PendingTime: "",
        AccepterGuid: "",
        WorkContent: "",
      });
    },

    onSelectMember(event) {
      console.log(event)
      this.work = event.MemberName
      this.memberInfo = event;
    },

    onRemoveData(index) {
      this.tableList.splice(index, 1);
    },

    resetPageData() {
      this.memberInfo = {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
      };
    },

    // 打开对话框
    onShowDialog() {
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .df-table {
    .flex-col;
    align-items: unset;

    div,
    span {
      box-sizing: border-box;
    }

    .df-table-body,
    .df-table-header {
      .df-tr {
        .flex-row;
        align-items: unset;
        flex-wrap: wrap;

        .df-th,
        .df-td {
          .flex-row;
          // align-items: unset;
          width: 120px;
          min-height: 40px;
          padding: 5px;
          font-size: 14px;
        }
        .df-th {
          color: #999;
        }
        .df-td {
          color: black;
        }

        .date,
        .accepter {
          width: 160px;
        }

        .control {
          width: auto;

          i {
            .flex-row;
            white-space: pre;
            cursor: pointer;

            &::before {
              font-size: 20px;
            }
          }
          .el-icon-circle-plus {
            color: #67c23a;
          }
          .el-icon-remove-outline {
            color: #f56c6c;
          }
        }
      }
    }

    .df-table-body {
      max-height: 600px;
      overflow-y: auto;
      .df-tr {
        padding: 10px 0;
        margin: 20px 0;
        background-color: #f1f1f1;
        border-radius: 5px;
        overflow: hidden;
        .el-date-editor {
          width: 100%;
          min-width: unset;
        }
      }

      .special {
        .flex-row;
        align-items: unset;
        width: 100%;
        padding: 10px;
        color: #999;

        .el-textarea {
          flex: 1;
        }
      }
    }
  }
}

.scrollbar;
</style>
